import axios from "axios";
import { feeBody } from "./interface";

const env = process.env;
export const site = env.REACT_APP_SITE;

// 사이트 정보
export const getSiteInfo = async () => {
    try {
        const res = await axios.get(
            `${env.REACT_APP_PROTOCOL}://${env.REACT_APP_URL}:${env.REACT_APP_PORT}/api/handle/${site}/info`
        );
        if (res.data.status === 200) return res.data.result;
    } catch (err) {
        return false;
    }
};

// 매물 정보
export const getSellInfo = async (sellNo: any) => {
    if (sellNo) {
        try {
            const res = await axios.get(
                `${env.REACT_APP_PROTOCOL}://${env.REACT_APP_URL}:${env.REACT_APP_PORT}/api/handle/${site}/products/${sellNo}`
            );

            return res.data;
        } catch (err) {
            return false;
        }
    }
};

// 상단 공지 문구 & 가격 안내 문구
export const getAnnounce = async (path: string) => {
    try {
        const res = await axios.get(
            `${env.REACT_APP_PROTOCOL}://${env.REACT_APP_URL}:${env.REACT_APP_PORT}/api/handle/${site}/announce/${path}`
        );

        if (res.data.status === 200) {
            return res.data.result;
        } else return false;
    } catch (err) {
        return false;
    }
};

// 이번비 게산
export const relocationFee = async (body: feeBody) => {
    try {
        const res = await axios.post(
            `${env.REACT_APP_PROTOCOL}://${env.REACT_APP_URL}:${env.REACT_APP_PORT}/api/handle/${site}/announce/fee`,
            body
        );
        if (res.data.status === 200) {
            return res.data.result;
        } else return false;
    } catch (err) {
        return false;
    }
};

// 인증 문자 전송
export const sendMsg = async (body: { phone: string; security: string }) => {
    try {
        const res = await axios.post(
            `${env.REACT_APP_PROTOCOL}://${env.REACT_APP_URL}:${env.REACT_APP_PORT}/api/handle/${site}/ppurio/sendmessage`,
            body
        );

        if (res.data.result.sendDate) {
            return true;
        } else return false;
    } catch (err) {
        return false;
    }
};

// 은행 목록 조회
export const getBankList = async () => {
    try {
        const res = await axios.get(
            `${env.REACT_APP_PROTOCOL}://${env.REACT_APP_URL}:${env.REACT_APP_PORT}/api/handle/${site}/bank`
        );

        if (res.data.status === 200) {
            return res.data.result;
        }
    } catch (err) {
        return false;
    }
};

// 약관 내용 조회
export const getCondition = async (code: string) => {
    try {
        const res = await axios.get(
            `${env.REACT_APP_PROTOCOL}://${env.REACT_APP_URL}:${env.REACT_APP_PORT}/api/handle/${site}/terms/${code}`
        );
        if (res.data.status === 200) {
            return res.data.result.message;
        }
    } catch (err) {
        return false;
    }
};

// 신청 내용 저장
export const saveProcess = async (body: any) => {
    try {
        const res = await axios.post(
            `${env.REACT_APP_PROTOCOL}://${env.REACT_APP_URL}:${env.REACT_APP_PORT}/api/handle/${site}/process`,
            body
        );

        if (res.data.status === 200) {
            return res.data.message;
        }
    } catch (err) {
        return false;
    }
};
