import { useState, useRef, useEffect } from "react";
import { PageWrap, MainBtn, Footer } from "../styles/recycle";
import Introduce from "../components/main/Introduce";
import Visual from "../components/main/Visual";
import PriceInfo from "../components/main/PriceInfo";
import Pay from "../components/main/Pay";
import { StorageType } from "../interface";
import { getAnnounce } from "../request";

function MainPage({
    data,
    setLocalStep,
    storageData,
    setStorageData,
    setPage,
    setActivate,
    activate,
}: {
    data: any;
    setLocalStep: React.Dispatch<React.SetStateAction<number>>;
    storageData: StorageType;
    setStorageData: React.Dispatch<React.SetStateAction<StorageType>>;
    setPage: React.Dispatch<React.SetStateAction<number>>;
    setActivate: React.Dispatch<React.SetStateAction<boolean>>;
    activate: boolean;
}) {
    const [tooltip, setTooltip] = useState<boolean>(false);
    const [priceTxt, setPriceTxt] = useState<{ message: string[]; cash: string[]; installment: string[] } | false>({
        message: [
            "<li>견적금액은 배송비에 의해 변동될 수 있습니다.<br>(배송비 최대금액 159,000원으로 계산 선반영되었습니다.)</li>\n        <li>배송비는 차량 출발지와 도착지 거리에 따라 책정되며, 상담단계에서 확정됩니다.</li>\n        <li>이전비는 차액 발생 시 계좌로 환급해드립니다.</li>\n        <li>위 예상 합계 금액은 성능보증보험료가 제외된 금액으로 차량마다 보험료가 다를 수 있어서 신청 이후에 상담사가 안내해 드립니다.</li>",
        ],
        cash: ["<li>결제를 위한 이체 한도를 미리 확인해 주세요.</li>"],
        installment: [
            "<li>차량비용만 할부 이용이 가능합니다.<br><strong>차량비용 이외의 금액은 계좌이체로 결제 진행됩니다.</strong></li>\n        <li>최대 대출한도는 차량금액으로 설정 됩니다.<br> 상담 시 대출 금액을 설정해주세요.</li>",
        ],
    });

    const scroll: any = useRef(null);
    let isFirst = true;

    useEffect(() => {
        txtSetting();
    }, []);

    useEffect(() => {
        if (storageData.payment_cd === "") {
            return;
        } else {
            scrollDown();
        }
    }, [storageData.payment_cd]);

    function setStorage(): void {
        if (storageData.payment_cd && activate) {
            setLocalStep(0);
            setPage(1);
        } else return;
    }

    async function txtSetting() {
        if (isFirst) {
            isFirst = false;
            const txt = await getAnnounce("price");

            txt ? setPriceTxt(txt) : null;
        }
    }

    function scrollDown() {
        scroll.current.scrollIntoView({
            behavior: "smooth",
            block: "end",
        });
    }

    return (
        <div ref={scroll} onClick={() => setTooltip(false)}>
            <Introduce />
            <PageWrap>
                <div>
                    <Visual data={data} bottom={false} />
                    <PriceInfo
                        tooltip={tooltip}
                        setTooltip={setTooltip}
                        data={data}
                        priceTxt={priceTxt}
                        setActivate={setActivate}
                    />
                    <Pay setStorageData={setStorageData} storageData={storageData} priceTxt={priceTxt} />
                </div>
            </PageWrap>
            <Footer>
                <div>
                    <MainBtn
                        backgrondColor="#0740E4"
                        color="#fff"
                        border="none"
                        activate={Boolean(storageData.payment_cd) && activate}
                        style={{ fontWeight: "700" }}
                        onClick={setStorage}
                    >
                        다음
                    </MainBtn>
                </div>
            </Footer>
        </div>
    );
}

export default MainPage;
