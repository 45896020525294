import styled from "styled-components";

export const FlowWrapper = styled.div`
    padding: 30px 0;

    .title-wrapper {
        position: relative;
        height: 75px;

        .number {
            position: absolute;
            bottom: 5px;
            left: 50%;
            transform: translate(-50%);
            color: #f3f3f3;
            font-size: 65px;
            font-weight: 800;
        }

        .title {
            position: absolute;
            bottom: 25px;
            left: 50%;
            transform: translate(-50%);
            font-weight: 700;
            font-size: 20px;
            line-height: 29px;
        }
    }

    .flow-descr {
        p {
            font-weight: 400;
            font-size: 15px;
            line-height: 22px;
        }

        span {
            font-weight: 400;
            font-size: 13px;
            line-height: 19px;
            color: #939393;
        }
    }

    img {
        display: block;
        width: 100%;
        margin: 50px auto 55px;
    }
`;
