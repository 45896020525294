import { useEffect, useState } from "react";
import styled from "styled-components";
import { getAnnounce } from "../../request";

const IntroduceWrap = styled.div`
    height: 88px;
    padding: 24px 17px;
    background-color: #0740e4;
    white-space: pre-line;
    color: #fff;
    font-weight: 500;
    font-size: 13px;
    line-height: 19px;
    text-align: center;
    letter-spacing: -0.02em;

    /* pc */
    @media screen and (min-width: 750px) {
        font-size: 15px;
        line-height: 22px;
    }
`;

function Introduce() {
    const [msg, setMsg] = useState<string>(
        "전문과와 1:1 라이브로 차량을 확인후 원하는 곳으로 받아보세요.\n 3+1일 동안 타보고 맘에 안들면 환불 할 수 있습니다."
    );

    let isFirst = true;
    useEffect(() => {
        msgSetting();
    }, []);

    async function msgSetting() {
        if (isFirst) {
            isFirst = false;
            const notice = await getAnnounce("notice");
            notice ? setMsg(notice.message) : null;
        }
    }

    return <IntroduceWrap>{msg}</IntroduceWrap>;
}

export default Introduce;
