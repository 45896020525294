import styled from "styled-components";

export const BridgeFooterWrapper = styled.footer`
    background-color: #f8f8f8;
    padding: 24px 16px 64px;
    color: #070707;

    div {
        width: 100%;
        display: flex;
        justify-content: flex-start;
    }

    p,
    a {
        font-weight: 400;
        font-size: 11px;
        line-height: 16px;
        position: relative;
    }

    .a-wrapper {
        padding-top: 9px;
        a {
            margin-right: 17px;
            position: relative;
            cursor: pointer;

            &::after {
                content: "";
                display: block;
                width: 1px;
                height: 5px;
                background-color: #c2c2c2;
                position: absolute;
                top: 5px;
                right: -9px;
            }

            &:last-child::after {
                display: none;
            }
        }
    }

    .description {
        font-size: 11px;
        line-height: 16px;
        text-align: left;
        margin: 16px 0;
    }

    .call-wrapper {
        a {
            display: flex;
        }
        img {
            margin-right: 7px;
        }
        p {
            margin-right: 8px;

            b {
                color: #3c3c3c;
                font-weight: 600;
                font-size: 11px;
            }
        }
    }
`;
