import { useEffect } from "react";

import step01 from "./img/hs_img_step02.png";
import step02 from "./img/hs_img_step03.png";
import step03 from "./img/hs_img_step04.png";
import step04 from "./img/hs_img_step05.png";
import step05 from "./img/hs_img_step06.png";

import { FlowWrapper } from "../../styles/bridgeStyle/flow";

// import axios from "axios";

interface flowType {
    number: string;
    title: string;
    descr: string;
    img: { src: any; alt: string };
}

const flowResource: flowType[] = [
    {
        number: "01",
        title: "홈배송 신청",
        descr: "홈배송 신청이 가능한 차량 상세화면에서<br /><b>[홈배송 신청]</b> 버튼을 클릭해 주세요.",
        img: { src: step01, alt: "실내 외부 옵션 타이어 사고 엔진 휠" },
    },
    {
        number: "02",
        title: "차란차에서 차량 점검",
        descr: "<p>신청하신 차량을 차란차에서 직접 점검 합니다.</p><span> - 차량 내/외관 상태 점검<br /> - 엔진 상태, 사고유무, 옵션, 타이어 등 약 151개의 항목 점검</span>",
        img: {
            src: step02,
            alt: "이 차량 혹시 사고 이력이 있나요? 안녕하세요 고객님! 현재 보고 계신 차량은 사고 이력이 없는 무사고 차량입니다.",
        },
    },
    {
        number: "03",
        title: "차량 전문가와 상담",
        descr: "<p>차량 전문가가 직접 고객님께<br /> 차량 상태를 안내해드립니다. </p><span>-전문가에게 궁금하신 점을 물어보거나 <br />점검 결과를 직접 피드백 받을 수 있습니다.</span>",
        img: {
            src: step03,
            alt: "지도",
        },
    },
    {
        number: "04",
        title: "결제 및 차량 배송",
        descr: "<p>계약금 및 잔금 결제가 완료되면<br />신청하신 날짜와 시간, 장소로 차량을 배송합니다.</p>",
        img: { src: step04, alt: "차량 배송 이미지" },
    },
    {
        number: "05",
        title: "차량 인수 및 드라이빙",
        descr: "<p>신청하신 차량을 인수합니다.<br />총 4일(1+3) 동안 드라이빙 하며 차량 확인 가능!</p><span>1일 : 차량 인수<br /> 3일 : 신청자 드라이빙<br />(마지막 날 드라이빙은 낮 12:00까지)</span>",
        img: { src: step05, alt: "명의 이전이 완료되었다는 알림톡 이미지" },
    },
    {
        number: "06",
        title: "구매 확정 후 명의이전",
        descr: "<p>명의이전 시 서류를 준비하실 필요가 없습니다.<br />(단, 온라인 이전등록 서비스 이용 시)</p><span>* 환불은 차량인수일을 포함해 4일차 낮12시까지 환불 가능하며<br /> 환불규정에 따라 추가 과금이 발생할 수 있습니다.</span>",
        img: { src: "", alt: "" },
    },
];

export default function Flow({ link_1, link_2 }: any) {
    useEffect(() => {
        flowResource.forEach((flow) => {
            const div = document.getElementById(flow.title);
            if (div) div.innerHTML = flow.descr;
        });
    }, []);

    // const getFile = async (url: string, name: string) => {
    //     if (url) {
    //         axios.get(url, { responseType: "blob" }).then((res) => console.log(res));
    //     }
    // };

    return (
        <div className="flow-bg">
            {flowResource.map((flow) => {
                const { number, title, img } = flow;
                return (
                    <FlowWrapper key={title}>
                        <div className="title-wrapper">
                            <p className="number">{number}</p>
                            <p className="title">{title}</p>
                        </div>
                        <div id={title} className="flow-descr"></div>

                        {img.src && img.alt ? <img {...img} /> : null}
                    </FlowWrapper>
                );
            })}
            <div className="flow-bottom">
                <a
                    // href={link_1?.url ? link_1.url : "#"}
                    // target="_parent"
                    // href={link_1.url}
                    href={link_1?.url ?? "#"}
                    target="_blank"
                    download="환불_규정_안내.pdf"
                    // onClick={() => getFile(link_1.url, "환불_규정_안내.pdf")}
                >
                    환불 규정 안내
                </a>
                <a
                    // href={link_2?.url ? link_2.url : "#"}
                    // target="_parent"
                    href={link_2?.url ?? "#"}
                    target="_blank"
                    download="홈서비스_약관.pdf"
                    // onClick={() => getFile(link_2.url, "홈서비스_약관.pdf")}
                >
                    홈서비스 약관 보기
                </a>
            </div>
        </div>
    );
}
