import { BridgeWrapper } from "../../styles/bridgeStyle/BridgePage";
interface BridgeProps {
    setBridge: React.Dispatch<React.SetStateAction<any>>;
    bridge: any;
}

export default function BridgeCHARANCHA({ setBridge, bridge }: BridgeProps) {
    return (
        <BridgeWrapper>
            <button
                style={{ color: "#000" }}
                onClick={() => setBridge((state: any) => ({ ...state, bridge_page: false }))}
            >
                신청 계속하기
            </button>
        </BridgeWrapper>
    );
}
