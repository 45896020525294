import { useState, useEffect } from "react";
import styled from "styled-components";
import queryString from "query-string";
import "./App.css";
import Page from "./pages/Page";
import LoadingPage from "./pages/LoadingPage";
import { getSiteInfo, getSellInfo } from "./request";

const AppWrap = styled.div`
    min-width: 360px;
    max-width: 1200px;
    margin: auto;

    @media screen and( max-width: 750px) {
        min-height: 800px;
    }
`;

function App() {
    const [data, setData] = useState<any>();
    const [bridge, setBridge] = useState<any>(false);

    useEffect(() => {
        getData();
    }, []);

    let isFirst = true;
    async function getData() {
        if (isFirst) {
            isFirst = false;

            let isAlert: boolean = false;
            const alertErr = (msg: string | boolean) => {
                isAlert = true;

                alert(msg === false ? "매물정보가 없어 실패하였습니다. 관리자에 문의하세요." : msg);
            };

            const siteInfo = await getSiteInfo();
            if (siteInfo) {
                setBridge(siteInfo);
                const sellNo = queryString.parse(window.location.search)?.sellNo;

                if (!sellNo) {
                    alertErr(false);
                } else {
                    const sellInfo = await getSellInfo(sellNo);
                    if (sellInfo.code && !isAlert) {
                        alertErr(sellInfo.message);
                    } else if ((!sellInfo || !sellInfo.simpleCar) && !isAlert) {
                        alertErr(false);
                    } else if (sellInfo.simpleCar) setData(sellInfo);
                }
            } else alertErr(false);
        }
    }

    return (
        <AppWrap>
            {data && bridge ? <Page data={data} bridge={bridge} setBridge={setBridge} /> : <LoadingPage />}
        </AppWrap>
    );
}

export default App;
