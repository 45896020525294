import styled from "styled-components";

export const BridgeWrapper = styled.div`
    text-align: center;
    position: relative;

    .top-div {
        background-color: #0740e4;
        padding-bottom: 0;

        padding: 0;
        p,
        h3,
        h5,
        span,
        a,
        b {
            color: #fff;
        }

        h5 {
            font-size: 20px;
            padding-top: 60px;
        }

        h3 {
            font-size: 40px;
        }

        div {
            margin: 40px 50px 20px;
            p {
                font-weight: 400;
                font-size: 17px;
                line-height: 25px;
            }

            span {
                display: block;
                font-weight: 400;
                font-size: 11px;
                line-height: 16px;
                margin: 16px 0;
            }
        }

        .main-img {
            width: 80%;
            // position: absolute;
            // bottom: 0;
            // left: 50%;
            // transform: translate(-50%);
        }

        // .img {
        //     width: 80%;
        //     min-height: 281px;
        //     max-height: 936px;
        //     height: 30%;
        // }
    }

    // @media (min-width: 350px) {
    //     .top-div .img {
    //         height: 60%;
    //     }
    // }

    .white-card-bg {
        background-color: #f6f7fb;
        padding: 48px 0;
        background-image: url("./img/hs_bg_cta.png");

        p {
            font-weight: 700;
            font-size: 17px;
            line-height: 25px;
        }

        span {
            font-weight: 400;
            font-size: 13px;
            line-height: 19px;
            color: #939393;
        }

        .white-card-wrapper {
            display: flex;
            flex-direction: column;
        }

        @media screen and (min-width: 750px) {
            .white-card-wrapper {
                flex-direction: row;

                div {
                    width: 30%;
                }
            }
        }
    }

    .flow-bg {
        .flow-bottom {
            margin: 0 auto;
            padding-bottom: 64px;
            display: flex;
            justify-content: center;

            a {
                display: block;
                border: 1px solid #d9d9d9;
                border-radius: 5px;
                color: #939393;
                padding: 12px 16px;
                font-size: 11px;
                cursor: pointer;
            }

            a: first-child {
                margin-right: 10px;
            }
        }
    }

    .blue-bottom {
        position: relative;
        background-color: #0740e4;
        padding: 24px 0;
        background-image: url("./img/Mask-group.png");
        background-repeat: no-repeat;
        background-position: center;
        p {
            color: #fff;
        }

        button {
            margin-bottom: 0;
        }
    }
`;

export const BridgeButton = styled.button`
    display: block;
    margin: 36px auto 49px;
    background: transparent;
    border: 1px solid rgba(255, 255, 255, 0.5);
    color: #fff;
    width: 187px;
    height: 54px;
    border-radius: 30px;
    font-weight: 700;
    font-size: 15px;
    line-height: 22px;
`;
