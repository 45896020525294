import { useEffect, useState } from "react";
import Header from "../components/Header";
import BridgePage from "./BridgePage";
import StepIndex from "../components/regist";
import MainPage from "./MainPage";
import CompletePage from "./CompletePage";

interface PageProps {
    data: any;
    bridge: any;
    setBridge: React.Dispatch<React.SetStateAction<any>>;
}

function Page({ data, bridge, setBridge }: PageProps) {
    const [activate, setActivate] = useState<boolean>(false);
    const [page, setPage] = useState<number>(0);
    const [step, setStep] = useState<number>(0);
    const [localStep, setLocalStep] = useState<number>(0);
    const [storageData, setStorageData] = useState<any>({
        sellNo: data.simpleCar.sellNo,
        payment_cd: "",
        step1: {
            customer_name: "",
            customer_hphone: "",
        },
        step2: { nominee_cd: "", index: 1 },
        step3: "",
        step4: {
            bank: { name: "", refund_bank_cd: "" },
            refund_accout_name: "",
            refund_accout_number: "",
        },
    });

    useEffect(() => {
        window.scrollTo({ top: 0 });
    }, [page, bridge?.bridge_page]);

    return (
        <>
            <Header step={step} page={page} bridge={bridge} />
            {bridge?.bridge_page ? (
                <BridgePage setBridge={setBridge} bridge={bridge} />
            ) : (
                <>
                    {
                        [
                            <MainPage
                                data={data}
                                setLocalStep={setLocalStep}
                                storageData={storageData}
                                setStorageData={setStorageData}
                                setPage={setPage}
                                setActivate={setActivate}
                                activate={activate}
                            />,
                            <StepIndex
                                step={step}
                                setStep={setStep}
                                storageData={storageData}
                                setStorageData={setStorageData}
                                setActivate={setActivate}
                                activate={activate}
                                setPage={setPage}
                                localStep={localStep}
                                setLocalStep={setLocalStep}
                            />,
                            <CompletePage data={data} />,
                        ][page]
                    }
                </>
            )}
        </>
    );
}

export default Page;
