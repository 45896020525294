import { BridgeFooterWrapper } from "../../styles/bridgeStyle/BridgeFooter";
import logo from "./img/logo_charancha.png";
import call from "./img/call.svg";

export default function BridgeFooter() {
    return (
        <BridgeFooterWrapper>
            <div>
                <img alt="logo" src={logo} />
            </div>
            <p className="description">
                차란차 주식회사는 통신판매중개로서 통신판매 당사자가 아니며 실 거래의 대한 상품정보, 거래내용 등에 대한
                의무와 책임은 각 판매자에게 있고 이로 인해 발생 된 손실, 손해에 대하여 책임지지 않습니다.
            </p>
            <div className="call-wrapper">
                <a href="tel:02-466-7923">
                    <img alt="phone" src={call} />
                    <p>
                        고객센터: <b>02-466-7923</b>
                    </p>
                </a>
                <p>
                    운영시간: <b>10:00 ~ 17:00</b>
                </p>
            </div>
        </BridgeFooterWrapper>
    );
}
