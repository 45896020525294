import Icon1 from "./img/1-icon.svg";
import Icon2 from "./img/2-icon.svg";
import Icon3 from "./img/3-icon.svg";
import styled from "styled-components";

interface WhiteCardProps {
    img: { src: any; alt: string };
    title: string;
    descr: string[];
}

const WhiteCardResorce: WhiteCardProps[] = [
    {
        img: { src: Icon1, alt: "자동차 전문가 상담" },
        title: "자동차 전문가 상담",
        descr: [
            "차를 잘 몰라도 걱정하지 마세요.",
            "차란차의 자동차 전문가가 차량을 직접 점검하고 궁금하신 점에 대해서 안내해 드립니다.",
        ],
    },
    {
        img: { src: Icon2, alt: "4일(1+3) 책임 환불제" },
        title: "4일(1+3) 책임 환불제",
        descr: [
            "구입 후 환불 걱정하지 마세요.",
            "4일 동안 차량의 상태를 충분히 확인하시고",
            "고객님의 환불 요청 시에 환불이 가능합니다.",
        ],
    },
    {
        img: { src: Icon3, alt: "편리한 배송" },
        title: "편리한 배송",
        descr: [
            "시간 때문에 차량 구매를 망설이지 마세요.",
            "차량이 배송될 곳의 주소지만 알려주시면",
            "문 앞까지 해당 차량을 배송해 드립니다.",
        ],
    },
];

const WhiteCardWrapper = styled.div`
    width: 80%;
    margin: 24px auto;
    padding: 20px 20px 16px;
    background-color: #fff;
    img {
        display: block;
        margin: 0 auto 17px;
    }

    p {
        font-weight: 600;
        font-size: 15px;
    }

    span {
        font-weight: 400;
        font-size: 13px;
        line-height: 19px;
    }
`;

export default function WhiteCard() {
    return (
        <div className="white-card-wrapper">
            {WhiteCardResorce.map((card) => {
                const { img, title, descr } = card;
                return (
                    <WhiteCardWrapper key={card.title}>
                        <img {...img} />
                        <p>{title}</p>
                        {descr.map((span) => {
                            return (
                                <span key={span}>
                                    {span}
                                    <br />
                                </span>
                            );
                        })}
                    </WhiteCardWrapper>
                );
            })}
        </div>
    );
}
