import { useState, useEffect } from "react";

import { PageWrap } from "../../styles/recycle";
import { StepProps } from "../../interface";

import Step1 from "./Step1";
import Step2 from "./Step2";
import Step3_1 from "./Step3_1";
import Step3_2 from "./Step3_2";
import Step3_3 from "./Step3_3";
import StepBtn from "./StepBtn";

export default function StepIndex({
    step,
    setStep,
    storageData,
    setStorageData,
    setActivate,
    activate,
    setPage,
    localStep,
    setLocalStep,
}: StepProps) {
    const [postCodeOpen, setPostCodeOpen] = useState<{
        nominee: boolean;
        business: boolean;
    }>({ nominee: false, business: false });
    const [phoneAuth, setPhoneAuth] = useState<boolean>(false);

    useEffect(() => {
        let input: any = document.getElementsByTagName("input");
        for (let key of input) {
            key.addEventListener("keydown", (e: any) => {
                if (e.key === "Enter") {
                    e.preventDefault();
                }
            });
        }
    }, [localStep]);

    function nextInput(e: any, s: string) {
        if (e.key === "Enter") {
            let input;

            if (s.slice(4, 7) === "3-2" && e.target.attributes[0].value.slice(-1) === "5") {
                setPostCodeOpen({ nominee: false, business: true });
                input = document.getElementsByName(s + "7");
                if (input.length) {
                    input[0].focus();
                }
            } else if (s.slice(4, 5) === "3" && e.target.attributes[0].value.slice(-1) === "2") {
                setPostCodeOpen({ nominee: true, business: false });
                input = document.getElementsByName(s + "4");
                if (input.length) input[0].focus();
            } else {
                input = document.getElementsByName(s + (Number(e.target.attributes[0].value.slice(-1)) + 1).toString());
                if (input.length) {
                    input[0].focus();
                }
            }
        }
    }

    return (
        <>
            <PageWrap>
                {
                    [
                        <Step1
                            setActivate={setActivate}
                            setStorageData={setStorageData}
                            storageData={storageData}
                            phoneAuth={phoneAuth}
                            nextInput={nextInput}
                        />,
                        <Step2
                            setActivate={setActivate}
                            storageData={storageData}
                            setStorageData={setStorageData}
                            setPhoneAuth={setPhoneAuth}
                        />,
                        <Step3_1
                            setActivate={setActivate}
                            setStorageData={setStorageData}
                            storageData={storageData}
                            nextInput={nextInput}
                            postCodeOpen={postCodeOpen.nominee}
                            setPostCodeOpen={setPostCodeOpen}
                        />,
                        <Step3_2
                            setActivate={setActivate}
                            setStorageData={setStorageData}
                            storageData={storageData}
                            nextInput={nextInput}
                            postCodeOpen={postCodeOpen}
                            setPostCodeOpen={setPostCodeOpen}
                        />,
                        <Step3_3
                            setActivate={setActivate}
                            setStorageData={setStorageData}
                            storageData={storageData}
                            nextInput={nextInput}
                            postCodeOpen={postCodeOpen.nominee}
                            setPostCodeOpen={setPostCodeOpen}
                        />,
                    ][localStep]
                }
            </PageWrap>
            <StepBtn
                step={step}
                setStep={setStep}
                localStep={localStep}
                setLocalStep={setLocalStep}
                activate={activate}
                storageData={storageData}
                setPage={setPage}
            />
        </>
    );
}
