import { useState } from "react";
import styled from "styled-components";
import Progress from "./regist/Progress";
import { RegistTitle } from "../styles/recycle";
import Modal from "react-modal";
import CloseModal from "../modal/CloseModal";
import "../modal/modal.css";
import { site } from "../request";

const HeaderWrapper = styled.div<{ bridge?: boolean; close: boolean }>`
    background-color: ${(props) => (props.bridge ? "#0740E4" : "#fff")};
    display: flex;
    align-items: center;
    width: 100%;
    height: 56px;
    position: relative;

    .close {
        width: ${(props) => (props.close ? "0" : "10px")};
        height: ${(props) => (props.close ? "0" : "10px")};
        margin-left: 18px;
        margin-right: 10px;
    }

    .beta {
        width: 33px;
        height: 18px;
        margin-left: 4px;
    }

    img {
        cursor: pointer;
    }

    @media screen and (min-width: 750px) {
        .close {
            display: none;
        }

        .header_title {
            margin-left: 32px;
        }
    }

    @media screen and (max-width: 749px) {
        position: sticky;
        top: 0;
        z-index: 100;
    }
`;

function Header({ step, page, bridge }: { step?: number; page: number; bridge?: any }) {
    const [closeModal, setCloseModal] = useState<boolean>(false);

    return (
        <>
            <Modal
                isOpen={closeModal}
                onRequestClose={() => setCloseModal(!closeModal)}
                overlayClassName="overlay"
                className="close_modal"
                ariaHideApp={false}
                shouldCloseOnOverlayClick={false}
            >
                <CloseModal setCloseModal={setCloseModal} mainTxt="홈서비스 신청을 취소하시겠습니까?" />
            </Modal>
            <HeaderWrapper bridge={bridge.bridge_page} close={site === "KB"}>
                <img
                    className="close"
                    src={`./img/close-${bridge.bridge_page ? "white" : "black"}.svg`}
                    alt="닫기버튼"
                    onClick={() => (site === "KB" ? null : setCloseModal(true))}
                />
                <RegistTitle className="header_title" bridge={bridge.bridge_page}>
                    {bridge.title}
                </RegistTitle>
                {bridge.beta && (
                    <img
                        className="beta"
                        src={bridge.bridge_page ? "./img/icon_beta_white.svg" : "./img/icon_beta_black.svg"}
                        alt="베타"
                    />
                )}

                {page === 1 && <Progress step={step} />}
            </HeaderWrapper>
        </>
    );
}

export default Header;
