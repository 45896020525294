import styled from "styled-components";
import { SubModal } from "./ConditionModal";
import { useEffect, useRef } from "react";
import { getCondition } from "../request";

const ConditionSubWrap = styled.div`
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    overflow: hidden;
    background-color: #fff;

    .condition_header {
        height: 56px;
        display: flex;
        align-items: center;
        padding-left: 8px;
        background-color: #fff;

        p {
            font-weight: 700;
            font-size: 17px;
            line-height: 25px;
            letter-spacing: -0.02em;
            color: #101010;
        }

        img {
            width: 32px;
            height: 32px;
            cursor: pointer;
        }
    }

    .condition_content {
        display: block;
        width: 100%;
        height: 100%;
        padding: 24px 16px 64px 16px;
        background-color: #fff;
        overflow: auto;
    }

    li,
    p,
    h5 {
        color: #555;
    }

    .sub_title {
        margin: 0;
    }
`;

interface SubModalProp {
    subModal: SubModal;
    setConditionSubOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

function ConditionSubModal({ subModal, setConditionSubOpen }: SubModalProp) {
    const condition_content = useRef<HTMLDivElement>(null);

    useEffect(() => {
        callCondition();
    }, [subModal.code]);

    async function callCondition() {
        const result = await getCondition(subModal.code);
        if (condition_content.current !== null) {
            condition_content.current.innerHTML = result;
        }
    }

    return (
        <ConditionSubWrap>
            <div className="condition_header">
                <img
                    src="./img/icon_navigation_back_black.svg"
                    alt="약관 뒤로 가기"
                    onClick={() => setConditionSubOpen(false)}
                />
                <p className="sub_title">{subModal.title}</p>
            </div>
            <div className="condition_content" ref={condition_content}></div>
        </ConditionSubWrap>
    );
}

export default ConditionSubModal;
