import { BridgeWrapper, BridgeButton } from "../../styles/bridgeStyle/BridgePage";
import WhiteCard from "./WhiteCard";
import Flow from "./Flow";
import BridgeFooter from "./BridgeFooter";

interface BridgeProps {
    setBridge: React.Dispatch<React.SetStateAction<any>>;
    bridge: any;
}

export default function BridgeKb({ setBridge, bridge }: BridgeProps) {
    const { link_1, link_2 } = bridge;

    return (
        <BridgeWrapper>
            <div className="top-div">
                <h5>구매 후 90일 추가 보증</h5>
                <h3>
                    KB차차차 홈배송 <br /> with
                    <br /> 차란차 홈서비스
                </h3>
                <div>
                    <p>
                        차량 상태를 자동차 전문가가
                        <br />
                        직접 점검하여 알려드리고
                    </p>
                    <b>
                        4일(1+3)동안 드라이빙 후<br />
                        구매 결정하는 홈배송!
                    </b>
                    <span>
                        ※ 본 서비스는 KB차차차에 등록된 수원 도이치 오토월드 차량을 대상으로
                        <br />
                        (주)차란차를 통해 제공됩니다. <br /> <a href="tel:02-466-7923">차란차 고객센터 : 02-466-7923</a>
                    </span>
                </div>
                <BridgeButton onClick={() => setBridge((state: any) => ({ ...state, bridge_page: false }))}>
                    신청 계속하기 <img src="./img/right-arrow.svg" alt="신청 계속하기" />
                </BridgeButton>

                <img
                    className="main-img"
                    alt="구매 후 보증이 되나요? 네! 90일간 추가 보증 됩니다!"
                    src="./img/hs_main.png"
                />
            </div>
            <div className="white-card-bg">
                <p>
                    이젠 홈배송을 이용해서 <br /> 안심하고 편리하게 내 차를 구매하세요.
                </p>
                <span>신청부터 명의이전까지 비대면으로 진행됩니다.</span>

                <WhiteCard />
            </div>
            <Flow link_1={link_1} link_2={link_2} />
            <div className="blue-bottom">
                <p>
                    4일(1+3)동안 드라이빙 후<br />
                    구매 결정하는 홈배송!
                </p>
                <BridgeButton
                    color="#707070"
                    onClick={() => setBridge((state: any) => ({ ...state, bridge_page: false }))}
                >
                    신청 계속하기 <img src="./img/right-arrow.svg" alt="신청 계속하기" />
                </BridgeButton>
            </div>
            <BridgeFooter />
        </BridgeWrapper>
    );
}
