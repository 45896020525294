import styled from "styled-components";
import { MainBtn, RegistTitle } from "../../styles/recycle";
import { StorageType } from "../../interface";

const PayWrap = styled.div`
    padding-bottom: 32px;
    overflow: visible;

    div {
        display: flex;
    }

    ul {
        li {
            font-weight: 400;
            font-size: 11px;
            line-height: 16px;
            letter-spacing: -0.02em;
            color: #939393;
            width: calc(100% - 32px);
            position: relative;
            padding-left: 10px;
            margin-top: 24px;

            p {
                font-weight: 700;
                color: #5f5e5e;
            }

            ::before {
                content: "";
                width: 3px;
                height: 3px;
                border-radius: 50%;
                background-color: #939393;
                position: absolute;
                left: 0;
                top: 5px;
            }
        }

        :last-child {
            margin-bottom: 100px;
        }

        @media screen and (min-width: 750px) {
            :last-child {
                margin-bottom: 140px;
            }

            li {
                font-size: 13px;
            }
        }
    }
`;

function Pay({
    storageData,
    setStorageData,
    priceTxt,
}: {
    storageData: StorageType;
    setStorageData: React.Dispatch<React.SetStateAction<StorageType>>;
    priceTxt: { message: string[]; cash: string[]; installment: string[] } | false;
}) {
    const site = process.env.REACT_APP_SITE;
    function payment(code: string) {
        setStorageData((state: any) => ({ ...state, payment_cd: `${site}_PAYMENT_${code}` }));
        const ulEl = document.getElementById("pay-notie");
        if (ulEl && priceTxt) {
            if (code === "001") {
                ulEl.innerHTML = priceTxt.cash[0];
            } else if (code === "002") {
                ulEl.innerHTML = priceTxt.installment[0];
            }
        }
    }

    return (
        <PayWrap>
            <RegistTitle>결제 수단을 선택해주세요</RegistTitle>
            <div>
                <MainBtn
                    type="button"
                    pay={storageData.payment_cd === `${site}_PAYMENT_001`}
                    activate={true}
                    onClick={() => payment("001")}
                    style={{ marginRight: "8px" }}
                >
                    현금
                </MainBtn>
                <MainBtn
                    type="button"
                    pay={storageData.payment_cd === `${site}_PAYMENT_002`}
                    activate={true}
                    onClick={() => payment("002")}
                >
                    현금 + 할부
                </MainBtn>
            </div>
            <ul id="pay-notie"></ul>
        </PayWrap>
    );
}

export default Pay;
