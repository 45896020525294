import styled from "styled-components";
import { useState, useRef, useEffect } from "react";
import { RegistTitle, Tooltip } from "../../styles/recycle";
import { relocationFee } from "../../request";
import { feeBody } from "../../interface";

const PriceWrap = styled.div<{ tooltip: boolean }>`
    padding-top: 16px;
    overflow: visible;
    padding-bottom: 40px;
    border-top: 1px dashed #c4c4c4;

    .price_ul {
        .underline {
            width: 100%;
            background: #d9d9d9;
        }

        li {
            position: relative;
            display: flex;
            justify-content: space-between;
            margin-bottom: 16px;

            .price_list_title {
                font-weight: 400;
                font-size: 15px;
                line-height: 22px;
                letter-spacing: -0.02em;
                color: #707070;

                .price_event {
                    position: absolute;
                    width: 31.16px;
                    height: 7.66px;
                    left: 96px;
                    top: 3px;
                }
            }

            .price_list_value {
                font-weight: 500;
                font-size: 15px;
                line-height: 22px;
                letter-spacing: -0.02em;
                color: #101010;
            }

            .fake_price {
                margin-right: 6px;
                color: #c2c2c2;
                text-decoration: line-through;
            }

            .price_arrow {
                position: absolute;
                top: 30%;
                width: 12px;
                height: 12px;
                margin-left: 7px;
            }

            .more_price {
                display: block;
                position: absolute;
                top: 31px;
                width: 100%;
                letter-spacing: -0.02em;
                font-weight: 400;
                font-size: 13px;

                li {
                    .more_price_title {
                        width: 100%;
                        color: #a7a7a7;
                        position: relative;
                        display: flex;
                        align-items: center;

                        img {
                            width: 15px;
                            height: 15px;
                            margin-left: 5px;
                        }

                        .main_tooltip {
                            z-index: 100;
                            position: absolute;
                            top: 26px;
                            left: -7.5px;
                            opacity: ${(props) => (props.tooltip ? "1" : "0")};
                            visibility: ${(props) => (props.tooltip ? "" : "hidden")};
                            transition: ${(props) =>
                                props.tooltip ? "opacity 700ms" : "opacity 500ms , visibility 500ms"};
                        }
                    }
                }

                .more_price_value {
                    color: #707070;
                }
            }
        }

        .total_cost {
            margin-top: 16px;
            margin-bottom: 26px;

            span {
                color: #555555;
                font-weight: 700;
                font-size: 15px;
                line-height: 22px;
            }

            span:nth-child(2) {
                color: #ff673e;
                font-weight: 600;
                font-size: 17px;
            }
        }
    }

    .list_style {
        margin-left: 10px;

        li {
            white-space: pre-line;
            text-indent: 0px;
            font-weight: 400;
            font-size: 11px;
            line-height: 20px;
            letter-spacing: -0.02em;
            color: #939393;
            margin-bottom: 6px;
            position: relative;

            ::before {
                content: "";
                width: 3px;
                height: 3px;
                border-radius: 50%;
                background-color: #939393;
                position: absolute;
                left: -9px;
                top: 8px;
            }
        }
    }

    @media screen and (min-width: 750px) {
        .list_style {
            li {
                font-size: 13px;
            }
        }

        .price_ul {
            li {
                .more_price {
                    li {
                        .more_price_title {
                            img {
                                width: 17px;
                                height: 17px;
                                margin-left: 8px;
                            }

                            .main_tooltip {
                                top: 27px;
                                left: -4px;
                            }
                        }
                    }
                }
            }
        }
    }
`;

function PriceInfo({ data, priceTxt, tooltip, setTooltip, setActivate }: any) {
    const [priceOpen, setPriceOpen] = useState<boolean>(false);
    const [info, setInfo] = useState<any>({});
    const ulRef = useRef<HTMLUListElement>(null);
    let isFirst = true;

    useEffect(() => {
        priceDataSetting();
    }, []);

    useEffect(() => {
        if (!!ulRef.current && priceTxt.message) {
            ulRef.current.innerHTML = priceTxt.message[0];
        }
    }, [priceTxt.message, ulRef.current]);

    async function priceDataSetting() {
        if (isFirst) {
            isFirst = false;
            const car = data.simpleCar;
            const feeInfo: feeBody = {
                type: car?.carTypeNm ? String(car?.carTypeNm) : "",
                body: car?.bodyTypeNm ? String(car?.bodyTypeNm) : "",
                fuel: String(car?.fuel),
                displacement: String(car?.displacement),
                location: "서울",
                cost: String(car?.sellPrice),
            };
            const priceData = await relocationFee(feeInfo);
            if (!priceData) {
                setActivate(false);
                return alert("이전비 계산에 실패했습니다. 관리자에게 문의하세요.");
            } else {
                setActivate(true);
                const {
                    acquisitionTax,
                    carCost,
                    discountBond,
                    tax1Cost,
                    tax2Cost,
                    numberPlateCost,
                    sellingCost,
                    transferCost,
                    homeserviceFee,
                    homeserviceFeeDisplay,
                    transferMinCost,
                    transferMaxCost,
                } = priceData;

                const relocation = acquisitionTax + discountBond + tax1Cost + tax2Cost + numberPlateCost;
                const transfer = addComma(relocation); // 이전비
                const management = addComma(relocation + sellingCost); // 부대비용
                const totalPrice = addComma(carCost + homeserviceFee + relocation + sellingCost + transferCost); // 예상합계

                setInfo({
                    homeserviceFee,
                    homeserviceFeeDisplay,
                    transfer,
                    management,
                    totalPrice,
                    sellingCost,
                    transferMinCost,
                    transferMaxCost,
                });
            }
        }
    }

    function addComma(data: any) {
        if (data) {
            return data.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        } else {
            return "0";
        }
    }

    let { releaseDt, sellPrice } = data.simpleCar;
    releaseDt = releaseDt ? `${releaseDt.slice(2, 4)}년${releaseDt.slice(4, 6)}월` : "00년 00월";
    sellPrice = sellPrice ? addComma(String(sellPrice) + "0000") : "";

    return (
        <PriceWrap tooltip={tooltip}>
            <div>
                <RegistTitle>구매 확정 시 필요한 금액이에요</RegistTitle>
                <ul className="price_ul">
                    <li>
                        <p className="price_list_title">차량 가격</p>
                        <p className="price_list_value">{sellPrice}원</p>
                    </li>
                    <li>
                        <p className="price_list_title">홈서비스 이용료</p>
                        <div>
                            <span className="price_list_value fake_price">
                                {info.homeserviceFeeDisplay ? info?.homeserviceFeeDisplay : "0"}원
                            </span>
                            <span className="price_list_value">
                                {info.homeserviceFee ? info?.homeserviceFee : "0"}원
                            </span>
                        </div>
                    </li>

                    {/* 2023-09-22 김낙균 요청
                    <li>
                        <p className="price_list_title">
                            90일 추가 보증
                            <img src="./img/EVENT.svg" alt="event" className="price_event" />
                        </p>
                        <span className="price_list_value">무료</span>
                    </li> */}

                    <li
                        style={{
                            marginBottom: priceOpen ? "67px" : "17px",
                        }}
                    >
                        <p
                            className="price_list_title"
                            style={{ position: "relative", cursor: "pointer" }}
                            onClick={() => setPriceOpen(!priceOpen)}
                        >
                            부대비용
                            <img
                                src="./img/w_icon_down_large.svg"
                                alt="부대비용"
                                className="price_arrow"
                                style={priceOpen ? { transform: "rotate(180deg)" } : {}}
                            />
                        </p>
                        <span className="price_list_value">{info.management && `${info.management ?? "0"}원`}</span>
                        <ul className="more_price" style={{ display: priceOpen ? "block" : "none" }}>
                            <li style={{ marginBottom: "3px" }}>
                                <p className="more_price_title">ㄴ이전비</p>
                                <p className="more_price_value">{info.transfer ? info.transfer : "0"}원</p>
                            </li>
                            <li style={{ marginBottom: "0px" }}>
                                <div className="more_price_title">
                                    <span style={{ color: "#a7a7a7" }}>ㄴ관리비용(매도비)</span>
                                    <img
                                        src="./img/w_icon_question_medium_gray.svg"
                                        alt="물음표"
                                        onClick={(e) => {
                                            setTooltip(!tooltip);
                                            e.stopPropagation();
                                        }}
                                        style={{ cursor: "pointer" }}
                                    />
                                    <Tooltip className="main_tooltip">
                                        매도비는 차종 또는 지역에 따라 다를 수 있습니다.
                                    </Tooltip>
                                </div>
                                <p className="more_price_value">
                                    {info.sellingCost ? addComma(info.sellingCost) : "0"}원
                                </p>
                            </li>
                        </ul>
                    </li>
                    <li>
                        <p className="price_list_title">배송비 (VAT 별도)</p>
                        <span className="price_list_value">
                            {info.transferMinCost &&
                                info.transferMaxCost &&
                                `${info.transferMinCost ? addComma(info.transferMinCost) : "0"}~${
                                    info.transferMaxCost ? addComma(info.transferMaxCost) : "0"
                                }원`}
                        </span>
                    </li>
                    <div className="underline"></div>
                    <li className="total_cost">
                        <span>예상 합계</span>
                        <span>{`${info.totalPrice ?? "0"}원`}</span>
                    </li>
                </ul>
            </div>

            <ul className="list_style" id="price-text-notice" ref={ulRef}></ul>
        </PriceWrap>
    );
}

export default PriceInfo;
